import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c29f9729',
  templateUrl: './c29f9729.component.html',
  styleUrls: ['./c29f9729.component.scss']
})
export class C29f9729Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
