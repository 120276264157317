export const Urls = {
  login: '/login',
  addAddress: '/company-address',
  addJob: '/job-order-request',
  userProfile: '/user-profile',
  partnerProfile: '/company-profile',
  partnersList: '/partners',
  staffList: '/staffs',
  getJobOderList: '/job-order-request-list',
  adminGetJobOderList: '/admin-job-order-request-list',
  getPriceCheckList: '/admin-price-check-list',
  validatePassword: '/validate-password',
  getMachinePrints: '/machine-prints',
  addProduct: '/products/add',
  viewPrivacyPolicy: '/privacy-policy',
  viewTermsandConditions: '/terms-and-conditions',
  viewAboutTheePrint: '/cms-about-thee-print',
  viewServices: '/cms-services',
  viewCoreValues: '/cms-core-values',
  viewFeaturedPartners: '/cms-featured-partners',
  viewCmsProducts: '/cms-products',
  viewCmsProductsCategory: '/cms-products-category',
  viewCmsEditProduct: '/cms-product',
  viewCmsEditProductCategories: '/cms-products-categories',
  viewProductsUpdate: '/cms-products-update-at',
  updateProduct: '/products/update',
  updateTermsandConditions: '/terms-and-conditions',
  updatePrivacyPolicy: '/privacy-policy',
  updateProfile: '/user-profile',
  updateCompanyProfile: '/company-profile',
  updateAboutTheePrint: '/cms-about-thee-print',
  updateServices: '/cms-services',
  updateCoreValues: '/cms-core-values',
  updateFeaturedPartners: '/cms-featured-partners',
  updateCmsProducts: '/cms-update-products',
  addCmsNewProducts: '/cms-add-new-product',
  productsList: '/products',	
  priceList: '/product-price',
  getConfirmation: '/partner-confirmation',
  confirmPartner: '/partner-confirmation/confirm',
  jobOrderDetails: '/job-order-request-view',
  singlePartner: '/partners-profile',
  getProductPrice: '/get-product-price',
  updateProductPrice: '/product-price',
  updateProductPrices: '/product-price/update',
  getCompanyAddress: '/company-address',
  getSingleCompanyAddress: '/company-address-single',
  getSingleProduct: '/products',
  deactivateAccount: '/user',
  getProductMediaPerPartner: '/partners-product-media',
  getProductMachinePrintPerPartner: '/partners-product-machine-print',
  getMachinePrintByMedia: '/partners-product-machine-print-media',
  getProductPartners: '/product-partners',
  getPartnersDashboard: '/partners-dashboard',
  getAdminDashboard: '/admin-dashboard',
  getJobOrderLog: '/job-order-log',
  sendEmailConfirmation: '/api/public/email/confirm-email.php?token=',
  sendEmailForgotPassword: '/api/public/email/reset-password.php?token=',
  sendEmailFirstLogin: '/api/public/email/new-login.php?partner_name=',
  sendEmailPriceCheck: '/api/public/email/price-check.php?partner_name=',
  sendEmailNewJobOrder: '/api/public/email/new-job-order.php?partner_name=',
  viewPartners: '/get-partners',
  getNotificationMessage: '/notification-types',
  updateNotificationMessage: '/notification-types',
  forgotPassword: '/forgot-password',
  validateResetToken: '/validate-reset-token',
  resetPassword: '/reset-password',
  getPendingDashboard: '/pending-jo-request',
  getGraphAdminDashboard: '/earnings',
  getConversation: '/chat-conversation',
  getConversationMessage: '/chat-conversation-message',
  getConversationUser: '/chat-conversation-user',
  sendEmailContactUs: '/api/public/email/contact_us.php',
  updateConversation: '/conversation/update',
  getConversationCount: '/chat-conversation-count',
  updateMessage: '/chat-conversation-message/update',
  getAdminAccounts: '/admin-accounts',
  notificationList: '/notification-list',
  partnerNotitificationEmail: '/api/public/email/partner-email.php?notification_id=',
  updateJob: '/update-job-order-request',
  seeNotification: '/seen-notification',
  seeAllNotification: '/seen-all-notification',
  getNewProductPrice: '/new-product-price-list',
  sendEmailChangeEmail: '/api/public/email/changed-email.php',
  resendCredentials: '/resend-credentials',
  validateToken: '/validate-token',
  deleteConversation: '/delete-conversation',
}   